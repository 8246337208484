<script setup>
    import { inject, watch, ref } from "vue";
    import { SyncDefinition } from 'o365.pwa.modules.client.SyncDefinition.ts';

    import { InjectionKeys } from 'o365-utils';

    const { pwaStoreKey } = InjectionKeys;

    const { state } = inject(pwaStoreKey);

    const syncDuration = ref('00:00');
    
    const currentSyncDefinition = ref({});
    let currentSyncDurationInterval = null;

    const calculateSyncDuration = () => {
        const syncStartDateTime = currentSyncDefinition.value?.currentSyncProgress?.dateTimeStart;

        if ((syncStartDateTime instanceof Date) === false) {
            return;
        }

        const syncEndDateTime = currentSyncDefinition.value?.currentSyncProgress?.dateTimeEnd ?? new Date();
        const durationInMilliseconds = syncEndDateTime - syncStartDateTime;
        const durationInDays = parseInt(durationInMilliseconds / (1000 * 60 * 60 * 24), 10);
        const durationHours = parseInt(durationInMilliseconds / (1000 * 60 * 60), 10) - (durationInDays * 24);
        const durationMinutes = parseInt(durationInMilliseconds / (1000 * 60), 10) - (durationInDays * 24 * 60) - (durationHours * 60);
        const durationSeconds = parseInt(durationInMilliseconds / 1000, 10) - (durationInDays * 24 * 60 * 60) - (durationHours * 60 * 60) - (durationMinutes * 60);

        syncDuration.value = `${durationInDays > 0 ? `${durationInDays.toString()}  ` : ''}${durationInDays > 0 || durationHours > 0 ? `${durationHours.toString().padStart(2, '0')}:` : ''}${durationMinutes.toString().padStart(2, '0')}:${durationSeconds.toString().padStart(2, '0')}`;
    };

    watch(
        () => state?.currentSync,
        (value, oldValue) => {
            if (value !== oldValue && value instanceof SyncDefinition) {
                currentSyncDefinition.value = value;

                currentSyncDurationInterval = setInterval(calculateSyncDuration, 100);
            } else {
                if (currentSyncDurationInterval !== null) {
                    clearInterval(currentSyncDurationInterval);
                    currentSyncDurationInterval = null;
                }
            }
        }
    );
</script>

<template>
    <p>{{syncDuration}}</p>
</template>
